import { maskingAPIKey } from '@/utils/string'
import { Chart } from 'highcharts-vue'
import cloneDeep from 'lodash.clonedeep'
import chartOptions from '@/views/Configs/_chartFields'

const OPEN = 1
const CLOSE = 2
const tabKeys = { OPEN, CLOSE }
const tabs = [
  { key: OPEN, title: 'Open Positions' },
  { key: CLOSE, title: 'Close Positions' },
]
const tableHeaders = [
  'Date',
  'Exchange',
  'OrderID',
  'Symbol',
  'Side',
  'Price',
  'Volume',
  'TP',
  'SL',
  'Profit',
  'Action',
  'Memo',
  'Logic P/L',
]

export default {
  name: 'Orders',
  components: { Chart },
  data: () => ({
    ready: false,
    logicsByMagicNumber: {},
    selected: [],
    skipToraripi: true,
    searchText: '',
    magicNumber: '',
    tabKeys,
    tabs,
    tab: { key: '', title: '' },
    tableHeaders,
    positions: [], // orders
    logics: [], // configs
    page: 1,
    totalPage: 1,
    chartOptions: null,
  }),
  async created() {
    this.logics = await this.$http.get('/api/v1/configs')
    this.logicsByMagicNumber = this.logics.reduce(
      (acc, el) => ({
        ...acc,
        [el.magicNumber]: el,
      }),
      {}
    )
    this.tab = this.tabs[0]
    this.ready = true
  },
  filters: {
    trimOrderId(orderId = '') {
      if (!orderId) {
        return 'Unknown'
      }
      return orderId.length < 11 ? orderId : `...${orderId.slice(-7)}`
    },
  },
  methods: {
    exchangeUserId(item) {
      const { magicNumber } = item
      const logic = this.logicsByMagicNumber[magicNumber]
      return logic ? logic.exchangeUserId : ''
    },
    selectExchangeUserId(item) {
      const exchangeUserId = this.exchangeUserId(item)
      if (!exchangeUserId) {
        return
      }
      const selected = this.logics
        .filter((el) => el.exchangeUserId === exchangeUserId)
        .map((el) => ({
          text: el.name,
          key: el.magicNumber,
        }))
      this.magicNumber = selected.map((el) => el.key).join(',')
      this.selected = selected
    },
    onTextChange(text) {
      if (!text) {
        this.magicNumber = ''
        this.selected = []
        return
      }

      const matchedLogics = this.logics.filter(
        ({ exchangeUserId, config: { key } }) => {
          return exchangeUserId === text || maskingAPIKey(text) === key
        }
      )
      if (matchedLogics) {
        this.magicNumber = matchedLogics
          .map((item) => item.magicNumber)
          .join(',')
      }
    },
    logicName(magicNumber) {
      return (this.logicsByMagicNumber[magicNumber] || {}).name || '-'
    },
    async getPositions() {
      let url =
        `/api/v1/positions?status=${this.tab.key}` +
        `&page=${this.page}&skipToraripi=${this.skipToraripi}`
      if (this.magicNumber) {
        url = `${url}&magicNumber=${this.magicNumber}`
      }
      const response = await this.$http.get(url)
      response.data.forEach((el) => {
        el.exchangeUserId = this.exchangeUserId(el)
      })
      this.positions = response.data
      this.totalPage = response.last
    },
    save(pos) {
      this.$http.post('/api/v1/positions', pos)
    },
    isClose(order) {
      return order.status === 2
    },
    async closeOrder(order) {
      if (this.isClose(order)) {
        return
      }

      const sure = confirm(`Click OK to close this order (#${order.id})`)
      if (sure) {
        try {
          await this.$http.post('/api/v1/positions/close', { id: order.id })
          const positions = this.positions.filter((item) => {
            return item.id !== order.id
          })
          this.positions = positions
          this.$notify({
            group: 'notify',
            title: 'Notifications',
            text: `Closed order (#${order.id})`,
          })
        } catch (e) {
          this.$notify({
            group: 'notify',
            title: 'Notifications',
            text: `Can't close order (#${order.id})`,
          })
        }
      }
    },
    onSearchChange(selectedLogics) {
      if (selectedLogics) {
        this.selected = selectedLogics
        this.magicNumber = selectedLogics.map((logic) => logic.key).join(',')
      } else {
        this.selected = []
        this.magicNumber = ''
      }
    },
    async showLogicChart(magicNumber) {
      this.chartOptions = cloneDeep(chartOptions)
      this.chartOptions.subtitle.text = this.logicName(magicNumber)
      const url = `/api/v1/positions?magicNumber=${magicNumber}&status=2&limit=1000`
      const logicHistory = await this.$http.get(url)

      logicHistory.data.sort((a, b) => {
        const tsa = new Date(a.openAt).getTime()
        const tsb = new Date(b.openAt).getTime()
        return tsa - tsb
      })

      let total = 0
      logicHistory.data.forEach(({ openAt, pl }) => {
        total += pl
        const ts = new Date(openAt).getTime()
        this.chartOptions.series[0].data.push([ts, pl])
        this.chartOptions.series[1].data.push([ts, total])
      })
    },
  },
  computed: {
    getOptions() {
      if (this.logics) {
        return this.logics.map(({ magicNumber, name }) => {
          return {
            key: magicNumber,
            text: name,
          }
        })
      }
      return []
    },
  },
  watch: {
    page() {
      this.getPositions()
    },
    tab() {
      // if tab change (open/close) changed, reset page to 1
      this.page = 1
      this.getPositions()
    },
    magicNumber() {
      this.page = 1
      this.getPositions()
    },
    skipToraripi() {
      this.page = 1
      this.getPositions()
    },
  },
}
